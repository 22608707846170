<template>
  <div ref="productListing" :class="['grid grid-cols-2 gap-x-[0.5rem] gap-y-[3.75rem] min-[768px]:grid-cols-3 min-[1024px]:grid-cols-4', isGrid5Layout ? 'min-[1280px]:grid-cols-5': null]">
    <template v-for="element in listingElements">
      <template v-if="element.apiAlias === 'product'">
        <a :href="getSeoUrlFromProduct(element)" class="block min-w-[130px]">
          <ProductBox v-bind="element"
                      @deleted-from-wishlist="fetchUsersWishlist"
          />
        </a>
      </template>
      <template v-else-if="element.apiAlias === 'zeit_ad'">
        <a :href="addQueryParamsToUrl('/ad/click', { id: element.id, link: encodeURIComponent(element?.link) })" :target="element?.isExternalLink ? '_blank' : '_self'" class="block min-w-[130px]">
          <div>
            <Media :media="element.media" />
            <div class="flex flex-col gap-y-[1rem] bg-transparent pt-[1.25rem]">
              <div>
                <div class="mb-[0.375rem] text-[0.875rem] text-[#808080] font-[400] leading-[100%]">Verlagsangebot</div>
                <div v-if="element?.label" class="mb-[0.375rem] text-[0.875rem] font-[600] leading-[100%]">{{ element.label }}</div>
              </div>
              <div class="text-[0.875rem] leading-[100%] underline">Hier bestellen</div>
            </div>
          </div>
        </a>
      </template>
    </template>
  </div>
</template>

<script setup>
import {listingFilters} from "@store/listingFilterStore";
import {listingSorting} from "@store/listingSortingStore";
import {apiFilterFromListingFilter, apiSortFromListingSorting, getSeoUrlFromProduct, useProduct} from "@shopware/product";
import ProductBox from "@components/category/ProductBox.vue";
import {computed, onBeforeUnmount, onMounted, provide, ref} from "vue";
import {throttle} from "@shopware/helper/event/index.js";
import {useCustomerAwareWishlist} from "@components/wishlist/wishlist.js";
import Media from "@components/product/components/Media.vue";
import CustomButton from "@components/vue-elements/CustomButton.vue";
import {addQueryParamsToUrl} from "@shopware/helper/url/index.js";

const {fetchProductListing, fetchProducts, searchProduct} = useProduct();
const {fetchWishlist} = useCustomerAwareWishlist();

const RESULT_LIMIT_FALLBACK = 24;
const SCROLL_THRESHOLD_PX = 500;

const props = defineProps({
  categoryId: {
    type: String
  },
  manufacturerId: {
    type: String
  },
  query: {
    type: String
  },
  products: {
    type: Array,
    required: true,
    default: []
  },
  listingAds: {
    type: Array,
    default: []
  },
  isGrid5Layout: {
    type: Boolean,
    default: false,
  },
  initPage: {
    type: Number,
    default: 1
  },
  initSorting: {
    type: String
  },
  resultLimit: {
    type: Number,
    default: 24
  },
});

const loadedProducts = ref(props.products);
const productListing = ref(null);

const currentPage = ref(props.initPage ?? 1);
const fetchRequestOngoing = ref(false);
const noFurtherProducts = ref(false);

const currentFilter = ref(null);
const currentSorting = ref(props.initSorting);

const wishlist = ref(null);

const resultLimit = computed(() => {
  return isNaN(props.resultLimit) ? RESULT_LIMIT_FALLBACK : props.resultLimit;
});

onMounted(() => {
  if (props.initSorting) {
    listingSorting.set(props.initSorting);
  }

  window.addEventListener('scroll', onScrollProductListing);
});

const listingElements = computed(() => {
  let elements = [...loadedProducts.value];

  for (const ad of props.listingAds) {
    const priority = isNaN(ad?.priority) ? 0 : ad.priority;

    if (priority >= elements.length) continue;

    elements.splice(priority, 0, ad);
  }

  return elements;
});

async function fetchUsersWishlist() {
  try {
    wishlist.value = await fetchWishlist({
      associations: {
        manufacturer: {}
      }
    });
  } catch (e) {
    wishlist.value = [];
    console.error(e);
  }
}

onMounted(async () => {
  await fetchUsersWishlist();
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScrollProductListing);
});


provide('wishlist', wishlist);

const onScrollProductListing = throttle(async () => {
  const bottomOfList = productListing.value.getBoundingClientRect().bottom <= window.innerHeight + SCROLL_THRESHOLD_PX;

  if (bottomOfList && !fetchRequestOngoing.value && !noFurtherProducts.value) {
    fetchRequestOngoing.value = true;
    currentPage.value++;

    let nextProducts = [];

    if (props.categoryId) {
      const sort = currentSorting.value ? (Array.isArray(currentSorting.value) ? currentSorting.value : apiSortFromListingSorting(currentSorting.value)) : apiSortFromListingSorting('neuheiten');
      const filter = currentFilter.value ? currentFilter.value : {};

      nextProducts = await fetchProductListing(
          props.categoryId,
          filter,
          sort,
          resultLimit.value,
          currentPage.value
      );
    } else if (props.manufacturerId) {
      // Listing is product manufacturer page

      nextProducts = await fetchProducts(
          [
            {
              field: 'manufacturerId',
              type: 'equals',
              value: props.manufacturerId
            }
          ],
          apiSortFromListingSorting('neuheiten'),
          resultLimit.value,
          currentPage.value
      );
    } else if (props.query) {
      // Listing is search result

      const response = await searchProduct(
          props.query,
          {},
          [],
          resultLimit.value,
          currentPage.value
      );

      nextProducts = response?.elements ?? [];
    }

    loadedProducts.value.push(...nextProducts);

    noFurtherProducts.value = nextProducts.length === 0;
    fetchRequestOngoing.value = false;
  }
}, 200);

listingFilters.listen(async (value) => {
  const sortValue = listingSorting.value;

  currentPage.value = 1;
  noFurtherProducts.value = false;
  currentFilter.value = apiFilterFromListingFilter(value);
  currentSorting.value = sortValue ? apiSortFromListingSorting(sortValue) : [];

  try {
    loadedProducts.value = await fetchProductListing(
        props.categoryId,
        currentFilter.value,
        currentSorting.value,
        resultLimit.value,
        currentPage.value
    );
  } catch (error) {
    console.error(error);
  }
});

listingSorting.listen(async (value) => {
  if (value === currentSorting.value) {
    return;
  }

  const filterValue = listingFilters.value;

  currentPage.value = 1;
  noFurtherProducts.value = false;
  currentFilter.value = filterValue ? apiFilterFromListingFilter(filterValue) : {};
  currentSorting.value = apiSortFromListingSorting(value);

  try {
    loadedProducts.value = await fetchProductListing(
        props.categoryId,
        currentFilter.value,
        currentSorting.value,
        resultLimit.value,
        currentPage.value
    );
  } catch (error) {
    console.error(error);
  }
});
</script>